import { Layout, Footer, Link } from 'components';
import {
  HeaderSection,
  LearnMoreSection,
  BenefitsSection,
  ApplyReasonsSection,
  RequirementsSection,
  StepsSection,
  TestimonialsSection,
} from 'page-components/program';
import { color } from 'style';
import headerImage from 'assets/images/women-back-to-work-header.svg';
import metaImage from 'assets/images/wbtw-meta.png';
import logoImage from 'assets/images/WBW-logo.svg';
import { BulletsSection } from 'components/BulletsLabeled';
import { fbTrack } from 'utils';
import { MenuItemProps, MenuItemTypeEnum } from 'components/Menu';
import { UnavailableModalContent } from 'components/UnavailableModalContent';
import { TestimonialProp } from 'page-components/program/TestimonialsSection';

const PROJECT_NAME = 'Women Back to Work';
const APPLY_LINK = 'https://form.typeform.com/to/L5zKvJIK';
const INFO_PDF = 'https://100mentors.s3-eu-west-1.amazonaws.com/static-assets/equall/Women_Back_to_Work.pdf';
const FAQ_PDF = 'https://100mentors.s3-eu-west-1.amazonaws.com/static-assets/equall/Women_Back_to_Work-FAQs.pdf';

const onApplyFBTrack = () => fbTrack('track', 'Lead', { content_name: 'BACK_TO_WORK' });

const menuItems: MenuItemProps[] = [
  { label: 'Συχνές Ερωτήσεις', type: MenuItemTypeEnum.LINK, href: FAQ_PDF },
  {
    href: APPLY_LINK,
    unavailableContent: <UnavailableModalContent projectName={PROJECT_NAME} />,
    type: MenuItemTypeEnum.APPLY_BUTTON,
    onApply: onApplyFBTrack,
    unavailable: UnavailableModalContent.isUnavailable(),
  },
];

const requirements: string[] = [
  'Άνω των 18 ετών',
  'Από όλη την Ελλάδα',
  'Ένταξη  στην αγορά εργασίας στους επόμενους 0-6 μήνες',
];

const bullets: BulletsSection[] = [
  {
    title: 'Επιμόρφωση',
    bullets: [
      'Εξειδικευμένη εκπαίδευση (training) διάρκειας 32 ωρών',
      'Απόκτηση και εμβάθυνση γνώσεων και καλών πρακτικών, με στόχο την επανένταξη στην αγορά εργασίας.',
      'Εκπαίδευση από καταξιωμένους εκπαιδευτικούς σε ειδικά διαμορφωμένες ενότητες.',
    ],
  },
  {
    title: 'Mentoring και Ανάπτυξη Δεξιοτήτων',
    bullets: [
      'Διαδραστικά εργαστήρια δεξιοτήτων διάρκειας (6 ωρών)',
      'Εξοικείωση και χρήση εργαλείων Παραγωγικής Τεχνητής Νοημοσύνης ως ψηφιακούς βοηθούς για την ανάπτυξη δεξιοτήτων και την καθοδήγηση στην αγορά εργασίας.',
      'Εξατομικευμένη καθοδήγηση από έμπειρους μέντορες και δικτύωση με επαγγελματίες από την αγορά εργασίας, με στόχο την ενδυνάμωση σε κάθε βήμα του προγράμματος.',
    ],
  },
];

const applyReasons = [
  'Θέλετε να επιστρέψετε δυναμικά στην αγορά εργασίας με ισότιμους όρους.',
  'Θα αναπτύξετε κάθετες και οριζόντιες δεξιότητες, για τις οποίες θα λάβετε πιστοποίηση.',
  'Θα λάβετε καθοδήγηση για το  πώς να συντάξετε το βιογραφικό σας και να παρουσιάσετε τον εαυτό σας και τα σχέδιά σας.',
  'Είναι εντελώς δωρεάν.',
  'Θα λάβετε γνώσεις και συμβουλές σχετικές με τα επόμενα βήματα της καριέρας σας.',
  'Θα συνδεθείτε με διακεκριμένους μέντορες που θα σας απαντήσουν σε στοχευμένες και εξελιγμένες ερωτήσεις.',
];

const testimonials: TestimonialProp[] = [
  {
    name: 'Δάφνη, Ιδιωτική υπάλληλος, Ξάνθη',
    text: 'Μαθαίνοντας ότι πρέπει να έχουμε αυτοπεποίθηση σε κάθε κατάσταση που αντιμετωπίζουμε, νιώθω τώρα πιο σίγουρη, ότι μπορώ να διαχειριστώ οποιαδήποτε πρόκληση.',
  },
  {
    name: 'Γεωργία, Σύμβουλος Ψυχικής Υγείας, Αθήνα',
    text: 'Το πρόγραμμα ενίσχυσε την αυτοπεποίθησή μου, ώστε να παρουσιάζω καλύτερα τον εαυτό μου ανεξαρτήτως τομέα απασχόλησης. Η ψυχολογική υποστήριξη που έλαβα με βοήθησε σημαντικά κατά την επιστροφή μου στην αγορά εργασίας.',
  },
  {
    name: 'Ε.Μ., Business Analyst, Πρέβεζα',
    text: 'Μέσα από το Women Back to Work ήρθα σε επαφή με αξιόλογους ανθρώπους και καταξιωμένους επαγγελματίες, σε μια πολύ δύσκολη περίοδο της ζωής μου, κάτι το οποίο με βοήθησε να δημιουργήσω τις κατάλληλες σχέσεις ώστε να ενταχθώ εκ νέου ομαλά στην αγορά εργασίας. Σας ευχαριστώ πολύ για αυτή την υπέροχη εμπειρία!',
  },
];

const Page = () => (
  <Layout
    logo={logoImage}
    meta={{
      title: PROJECT_NAME,
      description: 'Πρόγραμμα για την επανένταξη της γυναίκας στην αγορά εργασίας.',
      image: metaImage,
    }}
    menuItems={menuItems}
  >
    <HeaderSection
      title={
        <div>
          Θέλετε να επιστρέψετε δυναμικά στην αγορά εργασίας; <br />
          Το δωρεάν πρόγραμμα επιμόρφωσης <br />
          Women Back to Work <br />
          δημιουργήθηκε για εσάς!
        </div>
      }
      unavailableContent={<UnavailableModalContent projectName={PROJECT_NAME} />}
      applyLink={APPLY_LINK}
      disableApply={UnavailableModalContent.isUnavailable()}
      onApply={onApplyFBTrack}
      image={headerImage}
      imageWidth={440}
      imageAlt="Εργαζόμενη γυναίκα"
    />
    <LearnMoreSection
      title={`Ανακαλύψτε περισσότερα για το ${PROJECT_NAME}.`}
      text={
        <span>
          Το πρόγραμμα απευθύνεται σε όλες τις γυναίκες που στοχεύουν να επιστρέψουν δυναμικά στην αγορά εργασίας.
          <br />
          <br />
          Το πρόγραμμα αποτελείται από δύο σκέλη: α) <b>Επιμόρφωση</b> (training) συνολικής διάρκειας 32 ωρών, η οποία
          χωρίζεται σε 4 ενότητες, διαμορφωμένες ειδικά από το Alba Graduate Business School, The American College of
          Greece και β) <b>Mentoring και Ανάπτυξη Δεξιοτήτων</b>, ειδικά σχεδιασμένο από την ομάδα των 100mentors για
          την ανάπτυξη των οριζόντιων δεξιοτήτων των συμμετεχουσών, την προσωποποιημένη καθοδήγηση και την εμβάθυνση των
          γνώσεών τους, μέσω διαδραστικών εργαστηρίων και της εφαρμογής 100mentors.AI, η οποία συνδυάζει την Παραγωγική
          Τεχνητή Νοημοσύνη με την Ανθρώπινη Νοημοσύνη.
          <br />
          <br />
          Κάθε θεματική ενότητα επιμόρφωσης συνδυάζεται με ειδικά καταρτισμένη κοινότητα μεντόρων που πραγματοποιούν το
          σκέλος του mentoring, οι οποίοι πέρα από τις πολύτιμες εμπειρίες τους θα μοιραστούν καλές πρακτικές, συμβουλές
          καθοδήγησης, θα ενδυναμώσουν και θα βοηθήσουν τις συμμετέχουσες στην ανάπτυξη των οριζόντιων δεξιοτήτων (soft
          skills) τους.{' '}
          <b>
            Αναλυτικότερες πληροφορίες για την επιμόρφωση και το mentoring θα βρείτε{' '}
            <Link href={INFO_PDF} targetBlank>
              εδώ
            </Link>
          </b>
          .
          <br />
          <br />
          {(!UnavailableModalContent.isUnavailable() || UnavailableModalContent.isBeforeUnavailable()) &&
            'Οι δηλώσεις συμμετοχής για τον 5ο κύκλο ξεκινούν στις 9 Φεβρουαρίου 2024 και θα γίνονται δεκτές μέχρι και τις 25 Μαρτίου 2024.'}
          {UnavailableModalContent.isAfterUnavailable() &&
            'Οι δηλώσεις συμμετοχής για τον 5ο κύκλο ολοκληρώθηκαν στις 25 Μαρτίου 2024.'}
        </span>
      }
    />
    <RequirementsSection
      backgroundColor={color.hmPurple1}
      title="Προϋποθέσεις συμμετοχής"
      subtitle="Στο πρόγραμμα Women Back to Work μπορούν να συμμετέχουν όλες οι γυναίκες:"
      labels={requirements}
    />
    <BenefitsSection
      heading="Τι θα σας προσφέρει το πρόγραμμα Women Back to Work;"
      subHeading="Η ιδιαιτερότητα του Women Back to Work είναι ότι συνδυάζει τη θεωρητική επιμόρφωση με το mentoring, έτσι ώστε κάθε συμμετέχουσα να λάβει όλα τα απαραίτητα εφόδια για να επιτύχει το στόχο της και να εισέλθει στην αγορά εργασίας."
      bullets={bullets}
      footer={
        <p>
          Αναλυτικότερες πληροφορίες για την επιμόρφωση, το mentoring και την ανάπτυξη δεξιοτήτων θα βρείτε{' '}
          <Link href={INFO_PDF} targetBlank>
            εδώ
          </Link>
          .
        </p>
      }
      isListLabeled
    />
    <ApplyReasonsSection
      backgroundColor={color.hmPurple1}
      heading="Γιατί να δηλώσετε συμμετοχή στο πρόγραμμα Women Back to Work;"
      bullets={applyReasons}
      applyLink={APPLY_LINK}
      disableApply={UnavailableModalContent.isUnavailable()}
      unavailableContent={<UnavailableModalContent projectName={PROJECT_NAME} />}
      onApply={onApplyFBTrack}
    />
    <StepsSection projectName={PROJECT_NAME} title="Ποια είναι τα στάδια του προγράμματος;" faqLink={FAQ_PDF} />
    <TestimonialsSection
      backgroundColor={color.hmPurple1}
      title="Έχουν πει για τις δράσεις του προγράμματος EQUALL..."
      testimonials={testimonials}
    />
    <Footer
      signatures={[
        { title: 'Στρατηγικοί Συνεργάτες', logos: ['piraeus', '100mentors'] },
        { title: 'Συντελεστής Εκπαίδευσης', logos: ['deree'] },
      ]}
    />
  </Layout>
);

export default Page;
